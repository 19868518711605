import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';

// Importing Lottie animations
import videoCallAnimation from './lotties/CallAudio.json';
import audioCallAnimation from './lotties/Audio.json';
import chatAnimation from './lotties/Share.json';
import shareAnimation from './lotties/Share2.json';
import chatAnimation2 from './lotties/WhatsApp.json';

export default function HomeScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.state?.chatEndedMessage) {
      alert(location.state.chatEndedMessage);
    }
  }, [location.state?.chatEndedMessage]);

  const requestPermissions = async (path) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (stream) {
        setPermissionsGranted(true);
        localStorage.setItem('permissionsGranted', 'true');
        navigate(path);
      }
    } catch (err) {
      setError("You need to allow access to the camera and microphone to proceed.");
    }
  };

  const handleCallPress = () => permissionsGranted ? navigate('/call') : requestPermissions('/call');
  const handleAudioCallPress = () => permissionsGranted ? navigate('/audiocall') : requestPermissions('/audiocall');
  const handleChatPress = () => navigate('/chat');
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Best Omegle Alternative',
        text: 'Oyy, Look at this Best Omegle Alternative!',
        url: 'https://www.jilebi.fun',
      });
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <>
      <Helmet>
        <title>jilebi.fun - Best Omegle Alternative</title>
        <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
        <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
      </Helmet>

      <div style={styles.pageContainer}>
        <h1 style={styles.title} className='logo'>jilebi.fun</h1>
        <span style={styles.subtitle} className='logo'>Stranger Chatting - Free</span>

        {error && <div style={styles.error}>{error}</div>}

        <FeatureSection
          animationData={audioCallAnimation}
          title="Free Random Video Call"
          description="Connect Face-to-Face with strangers worldwide. Free video calling."
          buttonText="Start Video Call 🎥"
          onClick={handleCallPress}
          reverse={false} // Left-side Lottie
        />

        <FeatureSection
          animationData={videoCallAnimation}
          title="Free Random Audio Call"
          description="Talk to random people via audio calls. No video, just voices."
          buttonText="Start Audio Call 🎤"
          onClick={handleAudioCallPress}
          reverse={true} // Right-side Lottie
        />

        <FeatureSection
          animationData={chatAnimation}
          title="Free Random Chat"
          description="Instantly connect and chat with random strangers around the world."
          buttonText="Start Chat 💬"
          onClick={handleChatPress}
          reverse={false} // Left-side Lottie
        />

        <FeatureSection
          animationData={shareAnimation}
          title="Share with Friends"
          description="Enjoying jilebi.fun? Share it with your friends and spread the fun!"
          buttonText="Share Now 📤"
          onClick={handleShare}
          reverse={true} // Right-side Lottie
        />


        <FeatureSection
          animationData={chatAnimation2}
          title="Contact the Developer"
          description="Have suggestions or need support? Reach out to the dev directly!"
          buttonText="Chat With Developer 💬📱"
          onClick={() => window.open('https://wa.me/918639336964', '_blank')}
          reverse={false}
        />

      </div>

      {/* Splash Animation CSS */}
      <style>
        {`
          .splash::after {
            content: '';
            position: absolute;
            width: 120%;
            height: 120%;
            background: rgba(255, 255, 255, 0.5);
            top: -10%;
            left: -10%;
            border-radius: 50%;
            transform: scale(0);
            animation: splash 0.6s ease-out forwards;
          }

          @keyframes splash {
            to {
              transform: scale(1.5);
              opacity: 0;
            }
          }

          @media (max-width: 768px) {
            .feature-container {
              flex-direction: column !important;
              text-align: center;
            }

            .animation-container, .content-container {
              width: 100%;
            }

            .animated-button {
              width: 80%;
              margin: 0 auto;
            }

            .logo {
              display: none;
            }
          }

        `}
      </style>
    </>
  );
}

const FeatureSection = ({ animationData, title, description, buttonText, onClick, reverse }) => {
  return (
    <div 
      className="feature-container" 
      style={{ 
        ...styles.featureContainer, 
        flexDirection: reverse ? 'row-reverse' : 'row' // Alternates left & right
      }}
    >
      <div className="animation-container" style={styles.animationContainer}>
        <Lottie animationData={animationData} loop={true} />
      </div>

      <div className="content-container" style={styles.contentContainer}>
        <h2 style={styles.featureTitle} className='logo2'>{title}</h2>
        <p style={styles.featureDescription} className='logo2'>{description}</p>
        <button
          className="animated-button"
          onClick={onClick}
          style={styles.animatedButton}
          onMouseEnter={(e) => e.target.classList.add('splash')}
          onMouseLeave={(e) => e.target.classList.remove('splash')}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#121212',
  },
  title: {
    color: "#00E0FF",
    fontSize: "60px",
    fontStyle: "italic",
    margin: '0',
    marginTop: '20px',
    textAlign: 'center',
  },
  subtitle: {
    color: "#fff",
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: 'bold',
    marginBottom: '50px',
    textAlign: 'center',
  },
  featureContainer: {
    display: 'flex',
    width: '80%',
    margin: '10px auto',
    padding: '20px',
    borderRadius: '15px',
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(10px)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'flex-direction 0.3s ease',
  },
  animationContainer: {
    flex: '1',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    flex: '1',
    padding: '20px',
  },
  featureTitle: {
    fontSize: window.innerWidth < 768 ? '24px' : '32px',
    color: '#00E0FF',
    marginTop: '0px',
    marginBottom: '10px',
  },
  featureDescription: {
    fontSize: window.innerWidth < 768 ? '16px' : '18px',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '20px',
  },
  animatedButton: {
    padding: '12px 24px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#00E0FF',
    backgroundColor: '#FFFFFF20',
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 0.2s ease',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};
