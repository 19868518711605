import React, { useRef, useEffect, useState } from 'react';
// import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from './LoadingScreenForCall';
import SocketServiceCall from '../services/SocketServiceCall';
import './CallScreen.css';
import { Helmet } from 'react-helmet-async';

const CallScreen = () => {

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const socketRef = useRef(null);
  const roomIdRef = useRef(null);

  const [callActive, setCallActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();



  // Function to reduce the coins by 1 and save the updated value in localStorage
  
  // eslint-disable-next-line
  const reduceCoins = () => {
    let storedCoins = localStorage.getItem('coins');
    if (storedCoins) {
      let reducedCoins = Math.max(0, storedCoins - 1); // Ensure it doesn't go below 0
      localStorage.setItem('coins', reducedCoins);
    }
  };



  const iceServers = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
    ],
  };

  useEffect(() => {
    // socketRef.current = io.connect('http://localhost:5000');
    socketRef.current = SocketServiceCall.connect();
    

    socketRef.current.on('room-joined', (roomId) => {
      roomIdRef.current = roomId;
      startWebRTC(true);
      // reduceCoins(); //fn to reduce coins.
      setLoading(false);
      setCallActive(true);

      
    });

    socketRef.current.on('signal', (data) => {
      handleSignalingData(data);
    });

    socketRef.current.on('call-ended', () => {
      endCall();
    });

    const localVideo = localVideoRef.current;
    const remoteVideo = remoteVideoRef.current;
    const peerConnection = peerConnectionRef.current;

    return () => {

      // Clean up peer connection
      if (peerConnection) {
        peerConnection.close();
      }

      // Stop all local media tracks
      if (localVideo && localVideo.srcObject) {
        localVideo.srcObject.getTracks().forEach(track => track.stop());
        localVideo.srcObject = null;  // Clear the video element
      }

      // Stop all remote media tracks
      if (remoteVideo && remoteVideo.srcObject) {
        remoteVideo.srcObject.getTracks().forEach(track => track.stop());
        remoteVideo.srcObject = null;  // Clear the video element
      }
      socketRef.current.disconnect();
    };

  // eslint-disable-next-line
  }, []);

  const startWebRTC = async (initiator) => {
    if (!peerConnectionRef.current) {
      peerConnectionRef.current = new RTCPeerConnection(iceServers);

      peerConnectionRef.current.onicecandidate = (event) => {
        if (event.candidate) {
          socketRef.current.emit('signal', { candidate: event.candidate, roomId: roomIdRef.current });
        }
      };

      peerConnectionRef.current.ontrack = (event) => {
        remoteVideoRef.current.srcObject = event.streams[0];
      };
    }

    const stream = await navigator.mediaDevices.getUserMedia({
        video: true, 
        audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
        }
    });
    localVideoRef.current.srcObject = stream;

    stream.getTracks().forEach(track => peerConnectionRef.current.addTrack(track, stream));

    if (initiator) {
      const offer = await peerConnectionRef.current.createOffer();
      await peerConnectionRef.current.setLocalDescription(offer);
      socketRef.current.emit('signal', { offer, roomId: roomIdRef.current });
    }
  };

  let candidateQueue = [];

  const handleSignalingData = async (data) => {
    if (!peerConnectionRef.current) {
      console.error("PeerConnection is not initialized.");
      return;
    }

    if (data.offer) {
      if (peerConnectionRef.current.signalingState !== "stable") {
        console.warn("Ignoring duplicate offer.");
        return;
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.offer));
      processCandidateQueue();
      const answer = await peerConnectionRef.current.createAnswer();
      await peerConnectionRef.current.setLocalDescription(answer);
      socketRef.current.emit('signal', { answer, roomId: roomIdRef.current });
    }

    if (data.answer) {
      if (peerConnectionRef.current.signalingState !== "have-local-offer") {
        console.warn("Ignoring answer in wrong state.");
        return;
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.answer));
      processCandidateQueue();
    }

    if (data.candidate) {
      if (peerConnectionRef.current.remoteDescription) {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(data.candidate));
      } else {
        candidateQueue.push(data.candidate);
      }
    }
  };

  const processCandidateQueue = async () => {
    while (candidateQueue.length > 0) {
      const candidate = candidateQueue.shift();
      try {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (err) {
        console.error("Error adding ICE candidate:", err);
      }
    }
  };

  const endCall = () => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
  
    // Stop all local media tracks
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      const localTracks = localVideoRef.current.srcObject.getTracks();
      localTracks.forEach(track => track.stop());
      localVideoRef.current.srcObject = null;  // Clear the video element
    }
  
    // Stop all remote media tracks
    if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
      const remoteTracks = remoteVideoRef.current.srcObject.getTracks();
      remoteTracks.forEach(track => track.stop());
      remoteVideoRef.current.srcObject = null;  // Clear the video element
    }
  
    setCallActive(false);
    socketRef.current.emit('endcallmawa');

    toast.info('Chat Ended', {
      position: 'bottom-center',
      autoClose: 1500,
      onClose: () => {
        navigate('/');
      }
    });
  };
  
  
  

  return (

    <>
      
      <Helmet>
        <title>jilebi.fun - Best Omegle Alternative</title>
        <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
        <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
      </Helmet>

      
      <div>
        {loading ? (
          <div>
            <LoadingScreen navigatorForPage="call" />
          </div>
        ) : (
          <div className="video-call-container">
            <h1 style={{color:"#00E0FF" , fontStyle:'italic', fontWeight:'bold'}}>jilebi.fun - Video Call</h1>
            <div className="videos-wrapper">
              <div className="video-box">
                <video ref={localVideoRef} autoPlay playsInline muted className="video-element" />
                <span className="video-label">You</span>
              </div>
              <div className="video-box">
                <video ref={remoteVideoRef} autoPlay playsInline className="video-element" />
                <span className="video-label">Remote User</span>
              </div>
            </div>
            {callActive && (
              <button onClick={endCall} className="end-call-button">End Call</button>
            )}

            <ToastContainer />
          </div>

        )}
      </div>
    </>
  );
};

export default CallScreen;
