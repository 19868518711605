import React, { useRef, useEffect, useState } from 'react';
// import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from './LoadingScreenForCall';
import SocketServiceCall from '../services/SocketServiceAudio';
import './AudioCallScreen.css'; // Add this import for custom CSS
import { Helmet } from 'react-helmet-async';

const AudioCallScreen = () => {

  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const socketRef = useRef(null);
  const roomIdRef = useRef(null);
  const [callActive, setCallActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [localTalking, setLocalTalking] = useState(false);  // Indicates when local audio is active
  const [remoteTalking, setRemoteTalking] = useState(false);  // Indicates when remote audio is active

  const navigate = useNavigate();

  // Function to reduce the coins by 1 and save the updated value in localStorage
  // eslint-disable-next-line
  const reduceCoins = () => {
    let storedCoins = localStorage.getItem('coins');
    if (storedCoins) {
      let reducedCoins = Math.max(0, storedCoins - 1); // Ensure it doesn't go below 0
      localStorage.setItem('coins', reducedCoins);
    }
  };

  const iceServers = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
    ],
  };

  useEffect(() => {
    // socketRef.current = io.connect('http://localhost:5000');
    socketRef.current = SocketServiceCall.connect();

    socketRef.current.on('room-joined', (roomId) => {
      roomIdRef.current = roomId;
      startWebRTC(true);
      // reduceCoins(); //fn to reduce coins.
      setLoading(false);
      setCallActive(true);
    });

    socketRef.current.on('signal', (data) => {
      handleSignalingData(data);
    });

    socketRef.current.on('call-ended', () => {
      endCall();
    });

    return () => {
      // Clean up peer connection
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      // Stop all local audio tracks
      if (localAudioRef.current && localAudioRef.current.srcObject) {
        localAudioRef.current.srcObject.getTracks().forEach(track => track.stop());
        // eslint-disable-next-line
        localAudioRef.current.srcObject = null; // Clear the audio element
      }

      // Stop all remote audio tracks
      if (remoteAudioRef.current && remoteAudioRef.current.srcObject) {
        remoteAudioRef.current.srcObject.getTracks().forEach(track => track.stop());

        // eslint-disable-next-line
        remoteAudioRef.current.srcObject = null; // Clear the audio element
      }

      socketRef.current.disconnect();
    };

    // eslint-disable-next-line
  }, []);

  const startWebRTC = async (initiator) => {
    if (!peerConnectionRef.current) {
      peerConnectionRef.current = new RTCPeerConnection(iceServers);

      peerConnectionRef.current.onicecandidate = (event) => {
        if (event.candidate) {
          socketRef.current.emit('signal', { candidate: event.candidate, roomId: roomIdRef.current });
        }
      };

      peerConnectionRef.current.ontrack = (event) => {
        remoteAudioRef.current.srcObject = event.streams[0];
        analyzeStream(event.streams[0], setRemoteTalking);
      };
    }

    // Only request audio in getUserMedia
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      }
    });
    localAudioRef.current.srcObject = stream;
    analyzeStream(stream, setLocalTalking);

    stream.getTracks().forEach(track => peerConnectionRef.current.addTrack(track, stream));

    if (initiator) {
      const offer = await peerConnectionRef.current.createOffer();
      await peerConnectionRef.current.setLocalDescription(offer);
      socketRef.current.emit('signal', { offer, roomId: roomIdRef.current });
    }
  };

  // Function to analyze the audio stream and set the talking state
  const analyzeStream = (stream, setTalking) => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);
    analyser.fftSize = 512;

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const updateTalking = () => {
      analyser.getByteFrequencyData(dataArray);
      const isTalking = dataArray.some(value => value > 50); // Simple threshold for voice detection
      setTalking(isTalking);
      requestAnimationFrame(updateTalking);
    };

    updateTalking();
  };

  let candidateQueue = [];

  const handleSignalingData = async (data) => {
    if (!peerConnectionRef.current) {
      console.error("PeerConnection is not initialized.");
      return;
    }

    if (data.offer) {
      if (peerConnectionRef.current.signalingState !== "stable") {
        console.warn("Ignoring duplicate offer.");
        return;
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.offer));
      processCandidateQueue();
      const answer = await peerConnectionRef.current.createAnswer();
      await peerConnectionRef.current.setLocalDescription(answer);
      socketRef.current.emit('signal', { answer, roomId: roomIdRef.current });
    }

    if (data.answer) {
      if (peerConnectionRef.current.signalingState !== "have-local-offer") {
        console.warn("Ignoring answer in wrong state.");
        return;
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.answer));
      processCandidateQueue();
    }

    if (data.candidate) {
      if (peerConnectionRef.current.remoteDescription) {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(data.candidate));
      } else {
        candidateQueue.push(data.candidate);
      }
    }
  };

  const processCandidateQueue = async () => {
    while (candidateQueue.length > 0) {
      const candidate = candidateQueue.shift();
      try {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (err) {
        console.error("Error adding ICE candidate:", err);
      }
    }
  };

  const endCall = () => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }

    // Stop all local media tracks
    if (localAudioRef.current && localAudioRef.current.srcObject) {
      const localTracks = localAudioRef.current.srcObject.getTracks();
      localTracks.forEach(track => track.stop());
      localAudioRef.current.srcObject = null;  // Clear the audio element
    }

    // Stop all remote media tracks
    if (remoteAudioRef.current && remoteAudioRef.current.srcObject) {
      const remoteTracks = remoteAudioRef.current.srcObject.getTracks();
      remoteTracks.forEach(track => track.stop());
      remoteAudioRef.current.srcObject = null;  // Clear the audio element
    }

    setCallActive(false);
    socketRef.current.emit('endcallmawa');

    toast.info('Call Ended', {
      position: 'bottom-center',
      autoClose: 1500,
      onClose: () => {
        navigate('/');
      }
    });
  };

  return (

    <>

      <Helmet>
        <title>jilebi.fun - Best Omegle Alternative</title>
        <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
        <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
      </Helmet>


      <div>
        {loading ? (
          <div>
            <LoadingScreen navigatorForPage="audiocall" />
          </div>
        ) : (
          <div className="audio-call-container">
            <h1 style={{color:"#00E0FF", fontStyle:'italic', fontWeight:'bold'}}>jilebi.fun - Audio Call</h1>
            <div className="audio-indicator">
              <div className={`audio-circle ${localTalking ? 'talking' : ''}`}></div>
              <span style={{color:"#fff",fontWeight:'bold'}}>Local User</span>
            </div>
            <audio ref={localAudioRef} autoPlay playsInline muted />
            
            <div className="audio-indicator">
              <div className={`audio-circle ${remoteTalking ? 'talking' : ''}`}></div>
              <span style={{color:"#fff",fontWeight:'bold'}}>Remote User</span>
            </div>
            <audio ref={remoteAudioRef} autoPlay playsInline />
            
            {callActive && (
              <button onClick={endCall} style={{ marginTop: '20px', fontWeight:'bold' }}>End Call</button>
            )}

            <ToastContainer />
          </div>
        )}
      </div>
    </>
    
  );
};

export default AudioCallScreen;
