import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SocketService from '../services/SocketService'; // Adjust the import based on your socket setup
import { requestForToken,database,listenForMessages } from './firebaseConfig';
import { push, ref, set, get } from 'firebase/database';
import Lottie from "react-lottie";
import waitingAnimation from "./lotties/waiting.json"; // Place animation in src/animations
import noUserAnimation from "./lotties/Notfound.json"; // Another animation


export default function LoadingScreen() {
  const navigate = useNavigate();
  const socket = SocketService.socket; // Access the socket instance directly

  // eslint-disable-next-line
  const [isWaiting, setIsWaiting] = useState(true);
  
  // eslint-disable-next-line
  const [isWaitingNotify, setIsWaitingNotify] = useState(true);
  const [popup, setPopup] = useState(false);

  const [token, setToken] = useState("");

  // const [timerStarted, setTimerStarted] = useState(false);

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);





  
  useEffect(() => {

    
    const StoredTokenLocal = localStorage.getItem("StoredToken");
    if(StoredTokenLocal){
      setToken(StoredTokenLocal)
      // console.log("Token Stored = ", StoredTokenLocal);
      
    }
    else{
      // console.log("NO Stored Token Available");
      console.log("");
    }


    if (isWaiting) {
      const timer = setTimeout(() => {
        setPopup(true);
        SocketService.disconnect();
        // console.log('15 sec waited');
      }, 25000);

      return () => clearTimeout(timer); // Clean up the timer when component unmounts or isWaiting changes
    }
  }, [isWaiting]);


  const getToken = async () => {

    setLoading(true); // Start loading


    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await requestForToken();
      if (token) {
        setToken(token);
        // console.log("token: ",token);
        localStorage.setItem("StoredToken",token);
        storeToken(token)
      }
    }
    else{
      alert("You Blocked the notications. Allow in settings.")
    }

    setLoading(false); // End loading
  }

  const storeToken = (token) => {
    const tokenRef = push(ref(database, 'tokens')); // Generate unique key
    set(tokenRef, {
      token: token,
      timestamp: new Date().toISOString()
    })
      .then(() => {
        console.log("Token successfully stored in Firebase Realtime Database.");
      })
      .catch((error) => {
        console.error("Error storing token: ", error);
      });
  };



  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);



    // Create and append the ad script to a specific container
    const adContainer = document.getElementById('ad-space');

    if (adContainer) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        atOptions = {
          'key' : 'a75d2fa52b21762d01c320948e2c54af',
          'format' : 'iframe',
          'height' : 50,
          'width' : 320,
          'params' : {}
        };
      `;
      adContainer.appendChild(script);

      const scriptSrc = document.createElement('script');
      scriptSrc.src = "//www.topcpmcreativeformat.com/a75d2fa52b21762d01c320948e2c54af/invoke.js";
      scriptSrc.type = 'text/javascript';
      adContainer.appendChild(scriptSrc);
    }


    return () => {

      if (adContainer) {
        adContainer.innerHTML = ''; // Clean up ad container content
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socket) {
        socket.disconnect();
      }
    };



    
  }, [socket]);



  useEffect(() => {
    listenForMessages(); // Start listening for messages

    if (isWaitingNotify) {
      // Start a 5-second timeout
      const timer = setTimeout(() => {
        notifyAllUsers();
      }, 5000); 

      return () => clearTimeout(timer); // Cleanup timer
    }
    // eslint-disable-next-line
  }, []);


  const notifyAllUsers = async () => {
    // Fetch all FCM tokens from Firebase Realtime Database
    const tokensSnapshot = await get(ref(database, '/tokens'));
    const tokens = tokensSnapshot.val();

    if (tokens) {
      const tokenArray = Object.values(tokens).map(tokenObj => tokenObj.token);
      sendNotificationToUsers(tokenArray);
      setIsWaitingNotify(false);
    }
    else{
      console.log("Tokens not retrieved");
    }
  };

  const sendNotificationToUsers = async (tokenArray) => {
    
    const payload = {
      title: 'jilebi.fun',
      body: 'Somebody is Online!!!',
      tokens: tokenArray,
      existingToken: localStorage.getItem("StoredToken"),
      urlForNavigation: "chat"
    };

    try {
      // Replace with your actual API endpoint to handle FCM notifications
      // console.log("existing token = ", payload.existingToken);
      
      await fetch('https://firebasenotificationserver-t2px.onrender.com/send-notification', {
      // await fetch('http://localhost:5000/send-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleBack = () => {
    if (socket) {
      socket.disconnect(); // Disconnect the socket when going back
    }
    navigate('/');
  };

  // const handleCall = () => {
  //   if (socket) {
  //     socket.disconnect(); // Disconnect the socket when canceling
  //   }
  //   navigate('/call');
  // };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Best Omegle Alternative',
          text: 'Oyy, Look at this Best Omegle Alternative!!',
          url: 'https://www.jilebi.fun',
        })
        .then(() => {
          console.log('');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      console.log('Web Share API is not supported in your browser.');
    }
  };



  


  // **Lottie Animation Settings**
  const waitingOptions = {
    loop: true,
    autoplay: true,
    animationData: waitingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const noUserOptions = {
    loop: true,
    autoplay: true,
    animationData: noUserAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };



  return (
        <div style={styles.container}>
          {popup ? (
            !token ? (
              <div style={styles.card}>
                <Lottie options={noUserOptions} height={200} width={200} />
                <p style={styles.text}>No User Online!</p>
                <p style={styles.subText}>We will notify you when a user is online!</p>
                <button onClick={getToken} style={styles.button}>
                  {loading ? "Allowing..." : "Enable Notifications"}
                </button>
                <button onClick={handleBack} style={styles.secondaryButton}>Go Back</button>
              </div>
            ) : (
              <div style={styles.card}>
                <Lottie options={noUserOptions} height={200} width={200} />
                <p style={styles.text}>No User Online!</p>
                <p style={styles.subText}>Share the website with your best friend</p>
                <button onClick={handleShare} style={styles.button}>Share Now</button>
                <button onClick={handleBack} style={styles.secondaryButton}>Go Back</button>
              </div>
            )
          ) : (
            <div style={styles.card}>
  
              <div id="ad-space" style={styles.adContainer}></div>

              <Lottie options={waitingOptions} height={200} width={200} />
              <p style={styles.text}>Waiting for another user...</p>
              <button onClick={handleBack} style={styles.secondaryButton}>Go Back</button>
            </div>
          )}
        </div>
      );
}

// **Updated Styles with Glassmorphism UI**
const styles = {
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#121212",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(10px)",
    padding: "30px",
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
  },
  text: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#00E0FF",
    marginTop: "15px",
  },
  subText: {
    fontSize: "16px",
    color: "#fff",
    marginTop: "5px",
    textAlign: "center",
  },
  button: {
    marginTop: "15px",
    padding: "12px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#00E0FF",
    backgroundColor: "#FFFFFF20",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s",
  },
  secondaryButton: {
    marginTop: "10px",
    padding: "10px 18px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#FFFFFF20",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s",
  },


  
  adContainer: {
    width: '320px',
    height: '50px',
    position: 'fixed',
    bottom: '-20%', // Sticks it 5% above the bottom
    left: '50%',
    transform: 'translateX(-50%)', // Centers it horizontally
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '1px solid #ccc', // Optional border for debugging
  }


};








